const InsuranceClass = 
[
    {
        id:0,
        min:0,
        max:0,
        WorkInsurance:0,
        WorkInsuranceCompany:0,
        Retirement:0,
        HealthInsurance:0,
        HealthInsuranceCompany:0
    },
    {
        id:1,
        min:1,
        max:1500,
        WorkInsurance:266,
        WorkInsuranceCompany:933,
        Retirement:90,
        HealthInsurance:409,
        HealthInsuranceCompany:1286
    },
    {
        id:2,
        min:1501,
        max:3000,
        WorkInsurance:266,
        WorkInsuranceCompany:933,
        Retirement:180,
        HealthInsurance:409,
        HealthInsuranceCompany:1286
    },
    {
        id:3,
        min:3001,
        max:4500,
        WorkInsurance:266,
        WorkInsuranceCompany:933,
        Retirement:270,
        HealthInsurance:409,
        HealthInsuranceCompany:1286
    },
    {
        id:4,
        min:4501,
        max:6000,
        WorkInsurance:266,
        WorkInsuranceCompany:933,
        Retirement:360,
        HealthInsurance:409,
        HealthInsuranceCompany:1286
    },
    {
        id:5,
        min:6001,
        max:7500,
        WorkInsurance:266,
        WorkInsuranceCompany:933,
        Retirement:450,
        HealthInsurance:409,
        HealthInsuranceCompany:1286
    },
    {
        id:6,
        min:7501,
        max:8700,
        WorkInsurance:266,
        WorkInsuranceCompany:933,
        Retirement:522,
        HealthInsurance:409,
        HealthInsuranceCompany:1286
    },
    {
        id:7,
        min:8701,
        max:9900,
        WorkInsurance:266,
        WorkInsuranceCompany:933,
        Retirement:594,
        HealthInsurance:409,
        HealthInsuranceCompany:1286
    },
    {
        id:8,
        min:9901,
        max:11100,
        WorkInsurance:266,
        WorkInsuranceCompany:933,
        Retirement:666,
        HealthInsurance:409,
        HealthInsuranceCompany:1286
    },
    {
        id:9,
        min:11101,
        max:12540,
        WorkInsurance:301,
        WorkInsuranceCompany:1054,
        Retirement:752,
        HealthInsurance:409,
        HealthInsuranceCompany:1286
    },
    {
        id:10,
        min:12541,
        max:13500,
        WorkInsurance:324,
        WorkInsuranceCompany:1135,
        Retirement:810,
        HealthInsurance:409,
        HealthInsuranceCompany:1286
    },
    {
        id:11,
        min:13501,
        max:15840,
        WorkInsurance:380,
        WorkInsuranceCompany:1331,
        Retirement:950,
        HealthInsurance:409,
        HealthInsuranceCompany:1286
    },
    {
        id:12,
        min:15841,
        max:16500,
        WorkInsurance:396,
        WorkInsuranceCompany:1387,
        Retirement:990,
        HealthInsurance:409,
        HealthInsuranceCompany:1286
    },
    {
        id:13,
        min:16501,
        max:17280,
        WorkInsurance:415,
        WorkInsuranceCompany:1452,
        Retirement:1037,
        HealthInsurance:409,
        HealthInsuranceCompany:1286
    },
    {
        id:14,
        min:17281,
        max:17880,
        WorkInsurance:429,
        WorkInsuranceCompany:1502,
        Retirement:1073,
        HealthInsurance:409,
        HealthInsuranceCompany:1286
    },
    {
        id:15,
        min:17881,
        max:19047,
        WorkInsurance:457,
        WorkInsuranceCompany:1600,
        Retirement:1143,
        HealthInsurance:409,
        HealthInsuranceCompany:1286
    },
    {
        id:16,
        min:19048,
        max:20008,
        WorkInsurance:480,
        WorkInsuranceCompany:1681,
        Retirement:1200,
        HealthInsurance:409,
        HealthInsuranceCompany:1286
    },
    {
        id:17,
        min:20009,
        max:21009,
        WorkInsurance:504,
        WorkInsuranceCompany:1765,
        Retirement:1261,
        HealthInsurance:409,
        HealthInsuranceCompany:1286
    },
    {
        id:18,
        min:21010,
        max:22000,
        WorkInsurance:528,
        WorkInsuranceCompany:1848,
        Retirement:1320,
        HealthInsurance:409,
        HealthInsuranceCompany:1286
    },
    {
        id:19,
        min:22001,
        max:23100,
        WorkInsurance:554,
        WorkInsuranceCompany:1941,
        Retirement:1386,
        HealthInsurance:409,
        HealthInsuranceCompany:1286
    },
    {
        id:20,
        min:23101,
        max:24000,
        WorkInsurance:576,
        WorkInsuranceCompany:2016,
        Retirement:1440,
        HealthInsurance:409,
        HealthInsuranceCompany:1286
    },
    {
        id:21,
        min:24001,
        max:25250,
        WorkInsurance:607,
        WorkInsuranceCompany:2121,
        Retirement:1515,
        HealthInsurance:409,
        HealthInsuranceCompany:1286
    },
    {
        id:22,
        min:25251,
        max:26400,
        WorkInsurance:634,
        WorkInsuranceCompany:2218,
        Retirement:1584,
        HealthInsurance:409,
        HealthInsuranceCompany:1286
    },
    {
        id:23,
        min:26401,
        max:27600,
        WorkInsurance:662,
        WorkInsuranceCompany:2318,
        Retirement:1656,
        HealthInsurance:428,
        HealthInsuranceCompany:1344
    },
    {
        id:24,
        min:27601,
        max:28800,
        WorkInsurance:692,
        WorkInsuranceCompany:2420,
        Retirement:1728,
        HealthInsurance:447,
        HealthInsuranceCompany:1403
    },
    {
        id:25,
        min:28801,
        max:30300,
        WorkInsurance:728,
        WorkInsuranceCompany:2545,
        Retirement:1818,
        HealthInsurance:470,
        HealthInsuranceCompany:1476
    },
    {
        id:26,
        min:30301,
        max:31800,
        WorkInsurance:764,
        WorkInsuranceCompany:2672,
        Retirement:1908,
        HealthInsurance:493,
        HealthInsuranceCompany:1549
    },
    {
        id:27,
        min:31801,
        max:33300,
        WorkInsurance:800,
        WorkInsuranceCompany:2797,
        Retirement:1998,
        HealthInsurance:516,
        HealthInsuranceCompany:1622
    },
    {
        id:28,
        min:33301,
        max:34800,
        WorkInsurance:836,
        WorkInsuranceCompany:2924,
        Retirement:2088,
        HealthInsurance:540,
        HealthInsuranceCompany:1695
    },
    {
        id:29,
        min:34801,
        max:36300,
        WorkInsurance:872,
        WorkInsuranceCompany:3049,
        Retirement:2178,
        HealthInsurance:563,
        HealthInsuranceCompany:1768
    },
    {
        id:30,
        min:36301,
        max:38200,
        WorkInsurance:916,
        WorkInsuranceCompany:3208,
        Retirement:2292,
        HealthInsurance:592,
        HealthInsuranceCompany:1860
    },
    {
        id:31,
        min:38201,
        max:40100,
        WorkInsurance:962,
        WorkInsuranceCompany:3369,
        Retirement:2406,
        HealthInsurance:622,
        HealthInsuranceCompany:1953
    },
    {
        id:32,
        min:40101,
        max:42000,
        WorkInsurance:1008,
        WorkInsuranceCompany:3528,
        Retirement:2520,
        HealthInsurance:651,
        HealthInsuranceCompany:2045
    },
    {
        id:33,
        min:42001,
        max:43900,
        WorkInsurance:1054,
        WorkInsuranceCompany:3687,
        Retirement:2634,
        HealthInsurance:681,
        HealthInsuranceCompany:2138
    },
    {
        id:34,
        min:43901,
        max:45800,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:2748,
        HealthInsurance:710,
        HealthInsuranceCompany:2231
    },
    {
        id:35,
        min:45801,
        max:48200,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:2892,
        HealthInsurance:748,
        HealthInsuranceCompany:2347
    },
    {
        id:36,
        min:48201,
        max:50600,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:3036,
        HealthInsurance:785,
        HealthInsuranceCompany:2464
    },
    {
        id:37,
        min:50601,
        max:53000,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:3180,
        HealthInsurance:822,
        HealthInsuranceCompany:2581
    },
    {
        id:38,
        min:53001,
        max:55400,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:3324,
        HealthInsurance:859,
        HealthInsuranceCompany:2698
    },
    {
        id:39,
        min:55401,
        max:57800,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:3468,
        HealthInsurance:896,
        HealthInsuranceCompany:2815
    },
    {
        id:40,
        min:57801,
        max:60800,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:3648,
        HealthInsurance:943,
        HealthInsuranceCompany:2961
    },
    {
        id:41,
        min:60801,
        max:63800,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:3828,
        HealthInsurance:990,
        HealthInsuranceCompany:3107
    },
    {
        id:42,
        min:63801,
        max:66800,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:4008,
        HealthInsurance:1036,
        HealthInsuranceCompany:3253
    },
    {
        id:43,
        min:66801,
        max:69800,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:4188,
        HealthInsurance:1083,
        HealthInsuranceCompany:3399
    },
    {
        id:44,
        min:69801,
        max:72800,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:4368,
        HealthInsurance:1129,
        HealthInsuranceCompany:3545
    },
    {
        id:45,
        min:72801,
        max:76500,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:4590,
        HealthInsurance:1187,
        HealthInsuranceCompany:3726
    },
    {
        id:46,
        min:76501,
        max:80200,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:4812,
        HealthInsurance:1244,
        HealthInsuranceCompany:3906
    },
    {
        id:47,
        min:80201,
        max:83900,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:5034,
        HealthInsurance:1301,
        HealthInsuranceCompany:4086
    },
    {
        id:48,
        min:83901,
        max:87600,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:5256,
        HealthInsurance:1359,
        HealthInsuranceCompany:4266
    },
    {
        id:49,
        min:87601,
        max:92100,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:5526,
        HealthInsurance:1428,
        HealthInsuranceCompany:4485
    },
    {
        id:50,
        min:92101,
        max:96600,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:5796,
        HealthInsurance:1498,
        HealthInsuranceCompany:4705
    },
    {
        id:51,
        min:96601,
        max:101100,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:6066,
        HealthInsurance:1568,
        HealthInsuranceCompany:4924
    },
    {
        id:52,
        min:101101,
        max:105600,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:6336,
        HealthInsurance:1638,
        HealthInsuranceCompany:5143
    },
    {
        id:53,
        min:105601,
        max:110100,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:6606,
        HealthInsurance:1708,
        HealthInsuranceCompany:5362
    },
    {
        id:54,
        min:110101,
        max:115500,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:6930,
        HealthInsurance:1791,
        HealthInsuranceCompany:5625
    },
    {
        id:55,
        min:115501,
        max:120900,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:7264,
        HealthInsurance:1875,
        HealthInsuranceCompany:5888
    },
    {
        id:56,
        min:120901,
        max:126300,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:7578,
        HealthInsurance:1959,
        HealthInsuranceCompany:6151
    },
    {
        id:57,
        min:126301,
        max:131700,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:7902,
        HealthInsurance:2043,
        HealthInsuranceCompany:6414
    },
    {
        id:58,
        min:131701,
        max:137100,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:8226,
        HealthInsurance:2126,
        HealthInsuranceCompany:6677
    },
    {
        id:59,
        min:137101,
        max:142500,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:8550,
        HealthInsurance:2210,
        HealthInsuranceCompany:6940
    },
    {
        id:60,
        min:142501,
        max:147900,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:8874,
        HealthInsurance:2294,
        HealthInsuranceCompany:7203
    },
    {
        id:61,
        min:147901,
        max:150000,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:9000,
        HealthInsurance:2327,
        HealthInsuranceCompany:7305
    },
    {
        id:62,
        min:150001,
        max:156400,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:9000,
        HealthInsurance:2426,
        HealthInsuranceCompany:7617
    },
    {
        id:63,
        min:156401,
        max:162800,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:9000,
        HealthInsurance:2525,
        HealthInsuranceCompany:7929
    },
    {
        id:64,
        min:162801,
        max:169200,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:9000,
        HealthInsurance:2624,
        HealthInsuranceCompany:8240
    },
    {
        id:65,
        min:169201,
        max:175600,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:9000,
        HealthInsurance:2724,
        HealthInsuranceCompany:8552
    },
    {
        id:66,
        min:175601,
        max:182000,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:9000,
        HealthInsurance:2823,
        HealthInsuranceCompany:8864
    },
    {
        id:67,
        min:182001,
        max:189500,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:9000,
        HealthInsurance:2939,
        HealthInsuranceCompany:9229
    },
    {
        id:69,
        min:189501,
        max:197000,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:9000,
        HealthInsurance:3055,
        HealthInsuranceCompany:9594
    },
    {
        id:70,
        min:197001,
        max:204500,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:9000,
        HealthInsurance:3172,
        HealthInsuranceCompany:9959
    },
    {
        id:71,
        min:204501,
        max:212000,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:9000,
        HealthInsurance:3288,
        HealthInsuranceCompany:10325
    },
    {
        id:72,
        min:212001,
        max:219500,
        WorkInsurance:1100,
        WorkInsuranceCompany:3848,
        Retirement:9000,
        HealthInsurance:3404,
        HealthInsuranceCompany:10690
    },
]

export default InsuranceClass;
